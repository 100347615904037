<template>
  <div @click="showModal()" class="section__lk-content-item" v-if="result">
    <modal-pdf-view :id="'show-' + result.data.id" :link="result.data.file" :title="result.title" />
    <div class="section__lk-content-item-left">
      <div class="icon tutorial"></div>
      <div class="section__lk-content-item-separator"></div>
      <div class="section__lk-content-item-data">
        <div class="section__lk-content-item-data-title">
        </div>
        <div class="section__lk-content-item-data-content">
          <div>
            <div class="section__lk-content-item-data-title">
              Материал
            </div>
            <template v-if="result.description">
              {{ result.description }} <img src="/pic/profile/arrow-up-right.svg" />
            </template>
            <template v-else-if="result.title">
              {{ result.title }} <img src="/pic/profile/arrow-up-right.svg" />
            </template>
          </div>
        </div>
      </div>
    </div>
    <div class="section__lk-content-item-right section__lk-enroll">
      <div class="section__lk-enroll-icon">
        <img src="/pic/profile/icon-download.svg" />
        <div>{{ result.data.size }} Mb</div>
      </div>
      <div class="section__lk-enroll-status">
        <div class="status" :class="'status_' + result.status"></div>
      </div>
    </div>
  </div>
</template>

<script>
import ModalPdfView from "@/components/ModalPdfView.vue";
import $ from 'jquery';
import 'bootstrap'
window.JQuery = $
export default {
  name: "OnlineRowTutorial",
  components: {ModalPdfView},
  props: {
    result: {
      type: Object,
      required: true
    },
    id: {
      type: [String, Number],
      required: true
    }
  },
  mounted() {
    console.log('#show-' + this.result.data.id)
  },
  methods: {
    showModal() {
      $('#show-' + this.result.data.id).modal('show')
    }
  }
}
</script>