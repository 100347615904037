<template>
  <div class="section__lk-content-item"  @click="toTest()">
    <modal-test-start :id="id" v-if="result.data" :test="result.data" />
    <div class="section__lk-content-item-left">
      <div class="icon test"></div>
      <div class="section__lk-content-item-separator"></div>
      <div class="section__lk-content-item-data">
        <div class="section__lk-content-item-data-title">
        </div>
        <div class="section__lk-content-item-data-content">
          <div>
            <div class="section__lk-content-item-data-title">
              Тест
            </div>
            {{ result.description }} <img src="/pic/profile/arrow-up-right.svg" />
          </div>
        </div>
      </div>
    </div>
    <div class="section__lk-content-item-right section__lk-enroll">
      <div class="section__lk-enroll-icon d-flex flex-column">
        <div class="d-flex">
          <img src="/pic/profile/icon-time.svg" />
          <div>{{ result.data.duration }} мин.</div>
        </div>
        <div class="d-flex">
          <img src="/pic/profile/icon-check.svg" />
          <div>{{ result.data.questions_count }} {{ result.data.questions_count | pluralize('ru', ['вопрос', 'вопроса', 'вопросов']) }}</div>
        </div>
      </div>
      <div class="section__lk-enroll-status">
        <div class="status" :class="'status_' + result.status"></div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import ModalTestStart from "@/components/modal/ModalTestStart.vue";

export default {
  name: "OnlineRowTest",
  components: {ModalTestStart},
  props: {
    result: {
      type: Object,
      required: true
    },
    id: {
      type: String,
      default() {
        return 'test-start'
      }
    },
  },
  methods: {
    async toTest() {
      if(this.result.data && !this.result.data.results) {
        $('#' + this.id).modal('show')
      } else {
        await this.$router.push('/lk/test/' + this.result.data.id).catch(() => {})
      }
    }
  }
}
</script>

<style scoped>

</style>